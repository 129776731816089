@import '../../../defaults';
@import '~@anwalt.de/design-system/dist/css/components/navbar.css';
@import '~bootstrap/scss/_popover.scss';

.anw-logo {
  @include media-breakpoint-between(lg, lg) {
    max-width: 160px;
    width: 160px;
  }
}

.anw-sub-icon {
  display: none;
}

.anw-offcanvas.anw-offcanvas-www {
  .nav-link {
    display: block;
    padding: 0.9rem 2.3rem 0.9rem 2.6rem;
    width: 100%;
    word-break: break-word;

    a,
    a:hover {
      color: $body-color;
      font-weight: normal;
      text-decoration: none;
    }
  }

  .anw-navbar-no-icons {
    .nav-link {
      padding-left: 1rem;
    }
  }

  .anw-nav-link-level-1 {
    font-weight: $font-weight-bold;

    & > i {
      left: 0.8rem;
      top: auto;
    }
  }

  .anw-sidebar {
    margin-top: -5px;
    position: static;
    top: -80px;

    .anw-nav-item-level-2 {
      &:not(.anw-sub-heading) {
        .nav-link {
          padding-left: 2.6rem;
        }
      }

      .nav-link::after {
        border: 0;
        color: $blue;
        content: '\f054';
        font-family: $anw-fontawesome-font-family;
        font-weight: $font-weight-bold;
        margin: 0;
        position: absolute;
        right: 0.9rem;
        top: 0.9rem;
      }
    }

    .navbar-nav {
      .nav-link {
        color: $body-color;

        .active:hover {
          text-decoration: underline;
        }
      }

      .anw-sub-heading-icon {
        margin-left: -24px;
        width: 14px;
      }

      li.anw-nav-item-level-2 {
        margin-bottom: 0;

        .nav-link.active {
          color: $primary;
        }
      }
    }

    ul ul .nav-link.active {
      color: $primary;
      font-weight: $font-weight-normal;
    }
  }

  .anw-discount-tag-registration-cta {
    right: 0px;
    top: -10px;

    &.anw-discount-tag-registration-cta-offcanvas {
      right: 10px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .anw-offcanvas.anw-offcanvas-www {
    left: -300px;
    overflow: visible;
    position: static;
    visibility: visible;
    width: auto;

    .bs-popover-bottom {
      margin-top: 0.5rem;
    }

    .fa-li {
      position: static;
      width: auto;
    }

    .anw-main {
      &:hover {
        cursor: default;
      }
    }

    .anw-sub-heading {
      &:nth-child(n + 3) {
        margin-top: 0.5em;
      }
    }

    .anw-sub-icon {
      display: inline-block;
      font-size: 5px;
      margin-right: 0.35em;
      top: -0.6em;
    }

    .nav-link {
      .anw-nav-link-text {
        &:hover {
          border-bottom: none;
          text-decoration: underline;
        }
      }
    }

    .anw-nav-item-level-1:hover .anw-nav-text-level-1,
    .anw-nav-link-level-1.active .anw-nav-text-level-1 {
      border-bottom: 2px solid $primary;
      text-decoration: none;
    }

    .anw-navbar-submenu {
      background: $white;
      border: 1px solid $border-color;
      box-shadow: $box-shadow;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s ease-in-out;
      visibility: hidden;
      white-space: nowrap;
    }

    .arrow {
      display: block;
      height: $popover-arrow-height;
      left: 50px;
      margin: 0 $border-radius-lg;
      position: absolute;
      width: $popover-arrow-width;

      &::before,
      &::after {
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-style: solid;
        content: '';
        display: block;
        margin-left: 0;
        position: absolute;
      }
    }

    .navbar-nav > li:first-child {
      display: none;
    }

    .nav-link {
      border-bottom: 8px solid transparent;
      padding: 0;

      &::before {
        display: block;
      }

      &::after {
        display: none;
      }
    }

    .nav-item {
      padding: map-get($spacers, 2) map-get($spacers, 1) map-get($spacers, 2) map-get($spacers, 2);
      width: auto;

      &.anw-nav-item-level-1 {
        top: 6px;

        &:hover {
          > ul {
            max-width: 250px;
            opacity: 1;
            padding: map-get($spacers, 3);
            position: absolute;
            visibility: visible;
            width: 250px;

            &.anw-icons-list {
              padding-left: 2.2rem;
            }
          }
        }
      }

      &::before {
        display: inline-block;
      }

      &.anw-nav-item-level-2 {
        padding: 0;
        margin-bottom: map-get($spacers, 1);

        .nav-link {
          display: inline-block;
          font-size: $small-font-size;
          line-height: $line-height-base;
        }
      }
    }

    .popover-body {
      ul {
        display: block;
        padding-left: map-get($spacers, 3);
      }

      a:hover {
        text-decoration: underline;
      }
    }

    .collapsing {
      transition: none;
    }

    .anw-nav-login,
    .anw-nav-login:hover {
      color: $primary;
    }

    .btn {
      margin-top: 6px;
    }

    .anw-sidebar {
      background-color: inherit;
      box-shadow: none;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow: visible;

      .anw-nav-link-level-1,
      .anw-nav-link-level-1:hover,
      .anw-nav-item-level-1 > .nav-link {
        border-bottom: none !important;
        font-weight: $font-weight-bold;
        text-decoration: none;
      }

      .anw-nav-item-level-2:not(.anw-sub-heading) {
        .nav-link {
          padding-left: 0;
        }
      }

      .btn-info,
      .btn-info:hover {
        color: $white;
      }

      .fa-ul {
        max-width: 100%;
      }

      ul .nav-item > ul {
        display: block;
        position: absolute;
      }

      .anw-nav-account {
        border-left: 0;
        padding-right: 0;

        .nav-link,
        .nav-link:hover {
          cursor: pointer;
        }

        &.nav-item.anw-nav-item-level-1 {
          .arrow {
            left: auto;
            right: 30px;
          }

          > ul {
            max-width: none;
            padding-left: 1rem !important;
            right: 0;
            width: auto;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .anw-offcanvas.anw-offcanvas-www {
    .nav-item {
      padding-right: map-get($spacers, 2);
    }
  }
}

@include media-breakpoint-down(md) {
  .anw-navbar-submenu {
    margin-top: 0 !important;
  }

  .arrow {
    display: none;
  }

  .anw-no-js {
    .navbar-toggler-icon {
      cursor: pointer;
    }

    .anw-sidebar {
      .anw-nav-item-level-1 > .collapsed {
        background: $white;
      }

      .nav-link[data-bs-toggle].collapsed::after {
        content: '\f077';
      }
    }

    .collapse:not(.show) {
      display: block;
    }
  }
}
